<template>
  <div class="register">
    <el-radio-group v-model="tableType">
      <el-radio-button :label="0"
                       v-if="roleType">发布</el-radio-button>
      <el-radio-button :label="1">班级文化建设研讨登记</el-radio-button>
      <el-radio-button :label="2">班级特色活动登记</el-radio-button>
      <el-radio-button :label="3">班级主题活动登记</el-radio-button>
    </el-radio-group>
    <div class="header-button-query">
      <div class="header-button">
        <el-button type="success"
                   v-if="tableType===0"
                   @click="clickAddRegister">添 加</el-button>
        <el-button type="info"
                   @click="downOtherAward"
                   v-if="tableType===0">下 载</el-button>
      </div>
      <div>
        <el-select placeholder="请选择状态"
                   class="header-frame"
                   v-model="queryState">
          <el-option label="全部"
                     value=""></el-option>
          <el-option label="已完成"
                     :value="2"></el-option>
          <el-option label="进行中"
                     :value="0"></el-option>
          <el-option label="未完成"
                     :value="1"></el-option>
        </el-select>
        <el-date-picker v-model="queryTime"
                        type="daterange"
                        class="header-frame"
                        range-separator="至"
                        value-format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
        <el-select placeholder="请选择类型"
                   v-if="tableType===0"
                   class="header-frame"
                   v-model="queryType">
          <el-option v-for="type in typeList"
                     :key="type.value"
                     :label="type.name"
                     :value="type.value"></el-option>
        </el-select>
        <!-- <el-select placeholder="请选择状态"
                   v-if="tableType!==0"
                   class="header-frame"
                   v-model="queryState">
          <el-option label="未完成"
                     :value="1"></el-option>
          <el-option label="已完成"
                     :value="3"></el-option>
          <el-option label="进行中"
                     :value="2"></el-option>
        </el-select> -->
        <term-selector placeholder="请选择学期"
                       @change="querySemesterChange"
                       class="header-frame"
                       v-if="tableType!==0"
                       v-model="queryYear" />
        <!-- <el-select placeholder="请选择学期"
                   class="header-frame"
                   v-model="queryState">
        </el-select> -->
        <el-button type="primary"
                   @click="loadTask(1)">查 询</el-button>
      </div>
    </div>
    <el-table :data="registerTable">
      <el-table-column label="名称"
                       prop="name"
                       key="1"
                       v-if="tableType===0"
                       align="center"></el-table-column>
      <el-table-column label="学期"
                       key="7"
                       v-if="tableType===0"
                       prop="semester"
                       align="center"></el-table-column>
      <el-table-column label="类型"
                       prop="typeStr"
                       key="2"
                       v-if="tableType===0"
                       align="center"></el-table-column>
      <el-table-column label="开始时间"
                       key="3"
                       v-if="tableType===0"
                       prop="start_time"
                       align="center"></el-table-column>
      <el-table-column label="结束时间"
                       prop="end_time"
                       key="4"
                       v-if="tableType===0"
                       align="center"></el-table-column>
      <!-- <el-table-column label="状态"
                       prop="statusStr"
                       key="5"
                       v-if="tableType===0"
                       align="center"></el-table-column> -->

      <el-table-column label="主题"
                       key="8"
                       v-if="tableType===1"
                       prop="title"
                       align="center"></el-table-column>
      <el-table-column label="主题"
                       key="19"
                       v-if="tableType===2"
                       prop="name"
                       align="center"></el-table-column>
      <el-table-column label="主题"
                       key="20"
                       v-if="tableType===3"
                       prop="theme"
                       align="center"></el-table-column>
      <el-table-column label="班级"
                       key="14"
                       v-if="tableType!==0"
                       prop="cname"
                       align="center"></el-table-column>
      <el-table-column label="主持人"
                       key="9"
                       prop="host"
                       v-if="tableType!==0"
                       align="center"></el-table-column>
      <el-table-column label="参与教师"
                       key="10"
                       v-if="tableType!==0"
                       prop="mnames1"
                       align="center"></el-table-column>
      <el-table-column label="班主任"
                       key="17"
                       v-if="tableType===2||tableType===3"
                       prop="headmaster"
                       align="center"></el-table-column>
      <el-table-column label="考核小组"
                       key="11"
                       v-if="tableType!==0"
                       prop="mnames"
                       align="center"></el-table-column>
      <el-table-column label="时间"
                       key="12"
                       v-if="tableType!==0"
                       prop="activity_time"
                       align="center"></el-table-column>
      <el-table-column label="活动地点"
                       key="18"
                       v-if="tableType===3"
                       prop="address"
                       align="center"></el-table-column>
      <el-table-column label="状态"
                       key="13"
                       prop="statusStr"
                       align="center"></el-table-column>
      <el-table-column label="操作"
                       width="220px"
                       key="6"
                       align="center">
        <template slot-scope="scope">
          <div v-if="tableType===0">
            <el-button type="info"
                       size="mini"
                       :disabled="!scope.row.class_url"
                       @click="loadFile(scope.row.class_url)">下 载</el-button>
            <el-button type="primary"
                       size="mini"
                       @click="viewPushData(scope.row)">查 看</el-button>
            <el-button type="danger"
                       size="mini"
                       @click="deletePushData(scope.$index,scope.row.id,scope.row.type)">删 除</el-button>
          </div>
          <div v-if="tableType!==0">
            <!-- 班主任登记managerid=1,status=1未登记    进行中和完成都有撤回   
            考核check_id=自己有考核 check_status=1未考核-->
            <el-button type="info"
                       size="mini"
                       :disabled="!scope.row.url"
                       @click="loadFile(scope.row.url)">下 载</el-button>
            <el-button type="primary"
                       size="mini"
                       v-if="scope.row.host_id === userInfo.userid && scope.row.status == 1"
                       @click="registerData(scope.row)">登 记</el-button>
            <el-button type="info"
                       size="mini"
                       v-if="scope.row.host_id===userInfo.userid&&scope.row.status!==1"
                       @click="withdraw(scope.row.id)">撤 回</el-button>
            <el-button type="warning"
                       size="mini"
                       v-if="scope.row.check_id===userInfo.userid"
                       :disabled="scope.row.check_status!==1"
                       @click="registerData(scope.row,1)">考 核</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加发布的任务 -->
    <el-dialog :visible.sync="addTaskVisible"
               title="添加任务"
               width="600px">
      <el-form :model="taskForm"
               ref="taskForm"
               :rules="taskFormRules"
               label-width="120px">
        <el-form-item label="名称"
                      prop="name">
          <el-input v-model="taskForm.name"></el-input>
        </el-form-item>
        <el-form-item label="类型"
                      prop="type">
          <el-select placeholder="请选择类型"
                     v-model="taskForm.type">
            <el-option v-for="type in typeList"
                       :key="type.value"
                       :label="type.name"
                       :value="type.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间"
                      prop="startTime">
          <el-date-picker v-model="taskForm.startTime"
                          placeholder="请选择开始时间"
                          value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间"
                      prop="endTime">
          <el-date-picker v-model="taskForm.endTime"
                          placeholder="请选择开始时间"
                          value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="添加文件">
          <el-upload class="upload-demo"
                     action="/api/base/uploadByOss.do"
                     :headers="{Authorization:token}"
                     :on-remove="onRemoveFileTaskForm"
                     ref="upload"
                     :on-success="enclosureUploadSuccessTaskForm"
                     :data="{path:'register',name:''}"
                     :show-file-list="true">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="addTaskVisible=false">取 消</el-button>
        <el-button type="primary"
                   @click="addConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 登记表单 -->
    <el-dialog :visible.sync="registerVisible"
               title="登记"
               width="600px">
      <!-- 研讨活动 -->
      <el-form :model="registerForm"
               ref="registerForm"
               v-if="tableType===1"
               :disabled="assessment"
               :rules="registerFormRules"
               label-width="120px">
        <el-form-item label="研讨主题"
                      prop="title">
          <el-input v-model="registerForm.title"
                    placeholder="请输入研讨主题"></el-input>
        </el-form-item>
        <el-form-item label="主持人"
                      prop="host">
          <el-select placeholder="请选择主持人"
                     v-model="registerForm.host">
            <el-option v-for="teacher in hostTeacherList"
                       :key="teacher.id"
                       :label="teacher.name"
                       :value="teacher.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="研讨时间"
                      prop="time">
          <el-date-picker v-model="registerForm.time"
                          placeholder="请选择研讨时间"
                          value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="参与教师"
                      prop="participatingTeachers">
          <el-select placeholder="请选择参与教师"
                     multiple
                     filterable
                     v-model="registerForm.participatingTeachers">
            <el-option v-for="teacher in teacherList"
                       :key="teacher.id"
                       :label="teacher.name"
                       :value="teacher.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="研讨过程记录"
                      prop="discussionProcessRecord">
          <el-input placeholder="请输入研讨过程记录"
                    autosize
                    v-model="registerForm.discussionProcessRecord"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="考核小组参与人"
                      prop="assessmentTeamParticipants">
          <el-select placeholder="请选择考核小组参与人"
                     multiple
                     filterable
                     v-model="registerForm.assessmentTeamParticipants">
            <el-option v-for="teacher in teacherList"
                       :key="teacher.id"
                       :label="teacher.name"
                       :value="teacher.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="添加文件"
                      v-show=" !this.assessment">
          <el-upload class="upload-demo"
                     action="/api/base/uploadByOss.do"
                     :headers="{Authorization:token}"
                     :on-remove="onRemoveFileRegisterForm"
                     ref="upload"
                     :on-success="enclosureUploadSuccessRegisterForm"
                     :data="{path:'register',name:''}"
                     :show-file-list="true">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <!-- 特色活动 -->
      <el-form :model="characteristicActivitiesForm"
               ref="characteristicActivitiesForm"
               v-if="tableType===2"
               class="form-height"
               :rules="characteristicActivitiesFormRules"
               label-width="120px">
        <el-form-item label="活动名称"
                      prop="name">
          <el-input v-model="characteristicActivitiesForm.name"
                    :disabled="assessment"
                    placeholder="请输入活动名称"></el-input>
        </el-form-item>
        <el-form-item label="主持人"
                      prop="host">
          <el-select placeholder="请选择主持人"
                     v-model="characteristicActivitiesForm.host">
            <el-option v-for="teacher in hostTeacherList"
                       :key="teacher.id"
                       :label="teacher.name"
                       :value="teacher.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动时间"
                      prop="time">
          <el-date-picker v-model="characteristicActivitiesForm.time"
                          :disabled="assessment"
                          placeholder="请选择研讨时间"
                          value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="参与教师"
                      prop="participatingTeachers">
          <el-select placeholder="请选择参与教师"
                     :disabled="assessment"
                     multiple
                     filterable
                     v-model="characteristicActivitiesForm.participatingTeachers">
            <el-option v-for="teacher in teacherList"
                       :key="teacher.id"
                       :label="teacher.name"
                       :value="teacher.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考核小组参与人"
                      prop="assessmentTeamParticipants">
          <el-select placeholder="请选择考核小组参与人"
                     :disabled="assessment"
                     multiple
                     filterable
                     v-model="characteristicActivitiesForm.assessmentTeamParticipants">
            <el-option v-for="teacher in teacherList"
                       :key="teacher.id"
                       :label="teacher.name"
                       :value="teacher.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动目的"
                      prop="target">
          <el-input placeholder="请输入活动目的"
                    :disabled="assessment"
                    autosize
                    v-model="characteristicActivitiesForm.target"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="活动准备"
                      prop="prepare">
          <el-input placeholder="请输入活动准备"
                    :disabled="assessment"
                    autosize
                    v-model="characteristicActivitiesForm.prepare"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="活动简要过程"
                      prop="process">
          <el-input placeholder="请输入活动简要过程"
                    :disabled="assessment"
                    autosize
                    v-model="characteristicActivitiesForm.process"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="添加文件"
                      v-show=" !this.assessment">
          <el-upload class="upload-demo"
                     action="/api/base/uploadByOss.do"
                     :headers="{Authorization:token}"
                     :on-remove="onRemoveFileCharacteristicActivitiesForm"
                     ref="upload"
                     :on-success="enclosureUploadSuccessCharacteristicActivitiesForm"
                     :data="{path:'register',name:''}"
                     :show-file-list="true">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <div v-if="assessment">
          <el-form-item label="活动效果"
                        prop="activityEffect">
            <el-radio-group v-model="characteristicActivitiesForm.activityEffect">
              <el-radio :label="'优'"></el-radio>
              <el-radio :label="'良'"></el-radio>
              <el-radio :label="'中'"></el-radio>
              <el-radio :label="'差'"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="活动是否有特色"
                        prop="activityFeatures">
            <el-radio-group v-model="characteristicActivitiesForm.activityFeatures">
              <el-radio :label="'是'"></el-radio>
              <el-radio :label="'否'"></el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </el-form>
      <!-- 主题活动 -->
      <el-form :model="themeForm"
               ref="themeForm"
               v-if="tableType===3"
               class="form-height"
               :disabled="assessment"
               label-width="120px">
        <el-form-item label="活动主题"
                      :rules="[{ required: true, message: '请输入活动', trigger: 'blur' }]"
                      prop="name">
          <el-input v-model="themeForm.name"
                    placeholder="请输入活动名称"></el-input>
        </el-form-item>
        <el-form-item label="单元"
                      :rules='[{ required: true, message: "请输入单元", trigger: "blur" }]'
                      prop="name">
          <el-input v-model="themeForm.unit"
                    placeholder="请输入单元"></el-input>
        </el-form-item>
        <el-form-item label="主持人"
                      :rules='
      [{ required: true, message: "请选择主持人", trigger: "blur" }]'
                      prop="host">
          <el-select placeholder="请选择主持人"
                     v-model="themeForm.host">
            <el-option v-for="teacher in hostTeacherList"
                       :key="teacher.id"
                       :label="teacher.name"
                       :value="teacher.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动时间"
                      :rules=' [
          { required: true, message: "请选择时间", trigger: "blur" },
        ]'
                      prop="activityTime">
          <el-date-picker v-model="themeForm.activityTime"
                          placeholder="请选择活动时间"
                          value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="活动地点"
                      :rules=' [
          { required: true, message: "请输入地点", trigger: "blur" },
        ]'
                      prop="activityPlace">
          <el-input placeholder="请输入活动地点"
                    autosize
                    v-model="themeForm.activityPlace"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="活动目标"
                      :rules=' 
        [{ required: true, message: "请输入目的", trigger: "blur" }]'
                      prop="target">
          <el-input placeholder="请输入活动目标"
                    autosize
                    v-model="themeForm.target"
                    type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="参与教师"
                      :rules='[
          { required: true, message: "请选择参与教师", trigger: "blur" },
        ]'
                      prop="participatingTeachers">
          <el-select placeholder="请选择参与教师"
                     multiple
                     filterable
                     v-model="themeForm.participatingTeachers">
            <el-option v-for="teacher in teacherList"
                       :key="teacher.id"
                       :label="teacher.name"
                       :value="teacher.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考核小组参与人"
                      :rules='[
          { required: true, message: "请选择考核小组成员", trigger: "blur" },
        ]'
                      prop="assessmentTeamParticipants">
          <el-select placeholder="请选择考核小组参与人"
                     multiple
                     filterable
                     v-model="themeForm.assessmentTeamParticipants">
            <el-option v-for="teacher in teacherList"
                       :key="teacher.id"
                       :label="teacher.name"
                       :value="teacher.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="添加文件"
                      v-show=" !this.assessment">
          <el-upload class="upload-demo"
                     action="/api/base/uploadByOss.do"
                     :headers="{Authorization:token}"
                     :on-remove="onRemoveFileThemeForm"
                     ref="upload"
                     :on-success="enclosureUploadSuccessThemeForm"
                     :data="{path:'register',name:''}"
                     :show-file-list="true">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <hr>
        <div class="div-themeForm-technologicalProcess">流程 <el-button type="primary"
                     @click="addTechnologicalProcessForm">添 加</el-button>
        </div>
        <div v-for="(technologicalProcess,index) in themeForm.technologicalProcessList"
             :key="index">
          <hr>
          <el-form-item label="时间"
                        :rules="{required: true, message: '请选择时间'}"
                        :prop="'technologicalProcessList.'+index+'.time'">
            <el-date-picker v-model="technologicalProcess.time"
                            placeholder="请选择时间"
                            value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
          <el-form-item label="项目"
                        :rules="{
      required: true, message: '请输入活动目标'
    }"
                        :prop="'technologicalProcessList.'+index+'.project'">
            <el-input placeholder="请输入活动目标"
                      autosize
                      v-model="technologicalProcess.project"
                      type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="内容及要求"
                        :rules="{
      required: true, message: '请输入活动目标'
    }"
                        :prop="'technologicalProcessList.'+index+'.content'">
            <el-input placeholder="请输入活动目标"
                      autosize
                      v-model="technologicalProcess.content"
                      type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="负责人"
                        :rules="{
      required: true, message: '请选择负责人'
    }"
                        :prop="'technologicalProcessList.'+index+'.leading'">
            <el-select placeholder="请选择负责人"
                       v-model="technologicalProcess.leading">
              <el-option v-for="teacher in teacherList"
                         :key="teacher.id"
                         :label="teacher.name"
                         :value="teacher.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="地点"
                        :rules="{
      required: true, message: '请输入活动目标'
    }"
                        :prop="'technologicalProcessList.'+index+'.place'">
            <el-input placeholder="请输入活动目标"
                      autosize
                      v-model="technologicalProcess.place"
                      type="textarea"></el-input>
          </el-form-item>
          <el-button type="danger"
                     @click="deleteTechnologicalProcessForm(index)">删 除</el-button>
        </div>
      </el-form>
      <div slot="footer">
        <el-button @click="registerVisible=false">取 消</el-button>
        <!-- 确定登记的按钮 -->
        <el-button type="primary"
                   v-if="!assessment"
                   @click="registerConfirm">确 定</el-button>
        <!-- 确定考核的按钮 -->
        <el-button type="primary"
                   v-if="assessment"
                   @click="assessmentClick">确 定</el-button>
      </div>
    </el-dialog>
    <el-pagination background
                   layout="prev,pager,next"
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="loadTask"></el-pagination>
  </div>
</template>

<script>
import termSelector from "@/components/selectors/termSelector";
export default {
  name: "register",
  components: { termSelector },
  data() {
    return {
      tableType: 1,
      roleType: false, //管理员
      managementModule: JSON.parse(sessionStorage.getItem("userInfo")).nav1,
      queryState: "",
      queryTime: "",
      queryType: "",
      queryYear: "",
      page: { pageCount: 1, currentPage: 0 },
      registerTable: [],
      addTaskVisible: false,
      taskForm: {
        name: "",
        type: "",
        startTime: "",
        endTime: "",
        url: "",
      },
      taskFormRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        type: [{ required: true, message: "请选择类型", trigger: "blur" }],
        startTime: [
          { required: true, message: "请选择开始时间", trigger: "blur" },
        ],
        endTime: [
          { required: true, message: "请选择结束时间", trigger: "blur" },
        ],
      },
      typeList: [
        { name: "班级文化建设研讨登记", value: 1 },
        { name: "班级特色活动登记", value: 2 },
        { name: "班级主题活动登记", value: 3 },
      ],
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      registerForm: {
        title: "",
        host: "",
        time: "",
        participatingTeachers: [],
        discussionProcessRecord: "",
        assessmentTeamParticipants: [],
        url: "",
      },
      registerFormRules: {
        title: [{ required: true, message: "请输入主题", trigger: "blur" }],
        host: [{ required: true, message: "请选择主持人", trigger: "blur" }],
        time: [{ required: true, message: "请选择研讨时间", trigger: "blur" }],
        participatingTeachers: [
          { required: true, message: "请选择参与教师", trigger: "blur" },
        ],
        discussionProcessRecord: [
          { required: true, message: "请输入登记过程记录", trigger: "blur" },
        ],
        assessmentTeamParticipants: [
          { required: true, message: "请选择考核小组成员", trigger: "blur" },
        ],
      },
      registerVisible: false,
      teacherList: [],
      characteristicActivitiesForm: {
        name: "",
        cls: "",
        host: "",
        time: "",
        participatingTeachers: [],
        assessmentTeamParticipants: [],
        target: "",
        prepare: "",
        process: "",
        activityEffect: "",
        activityFeatures: "",
        url: "",
      },
      characteristicActivitiesFormRules: {
        name: [{ required: true, message: "请输入活动", trigger: "blur" }],
        cls: [{ required: true, message: "请选择班级", trigger: "blur" }],
        host: [{ required: true, message: "请选择班主任", trigger: "blur" }],
        time: [{ required: true, message: "请选择时间", trigger: "blur" }],
        participatingTeachers: [
          { required: true, message: "请选择参与教师", trigger: "blur" },
        ],
        assessmentTeamParticipants: [
          { required: true, message: "请选择考核小组成员", trigger: "blur" },
        ],
        target: [{ required: true, message: "请输入目的", trigger: "blur" }],
        prepare: [{ required: true, message: "请输入准备", trigger: "blur" }],
        process: [{ required: true, message: "请输入过程", trigger: "blur" }],
        activityEffect: [
          { required: true, message: "请选择活动效果", trigger: "change" },
        ],
        activityFeatures: [
          {
            required: true,
            message: "请选择活动是否有特色",
            trigger: "change",
          },
        ],
      },
      themeForm: {
        name: "",
        host: "",
        unit: "",
        cls: "",
        activityTime: "",
        activityPlace: "",
        target: "",
        url: "",
        participatingTeachers: [],
        assessmentTeamParticipants: [],
        technologicalProcessList: [
          {
            time: "",
            project: "",
            content: "",
            leading: "",
            place: "",
          },
        ], //流程
      },
      assessment: false,
      hostTeacherList: [],
      token: sessionStorage.getItem("token"),
    };
  },
  watch: {
    tableType: function (newTableType) {
      this.loadTask(1);
    },
  },
  created() {
    //判断是否为管理员，1401为德育管理的班级文化建设的登记页面id
    for (let i = 0; i < this.managementModule.length; i++) {
      if (this.managementModule[i].id === 1401) {
        this.roleType = true;
        this.tableType = 0;
        break;
      }
    }
    this.loadTask(1);
    this.loadTeacher();
  },
  methods: {
    loadTask(page) {
      this.registerTable = [];
      let data = {
        indexNo: page,
        status: this.queryState,
      };
      let apiUrl = "/base/queryClassDiscuss.do";
      if (this.queryTime) {
        data.begin_time = this.queryTime[0];
        data.end_time = this.queryTime[1];
      }
      if (this.queryYear) {
        data.yearid = this.queryYear;
      }
      if (this.tableType === 0) {
        data.type = this.queryType;
        apiUrl = "/base/queryClassCultural.do";
      }
      if (this.tableType === 2) {
        apiUrl = "/base/queryClassSpecial.do";
      }
      if (this.tableType === 3) {
        data.yearid = this.queryYear;
        apiUrl = "/base/queryClassTheme.do";
      }
      this.$post(apiUrl, data)
        .then((res) => {
          this.page.pageCount = res.data.pageCount;
          this.page.currentPage = res.data.indexNo;
          if (this.tableType === 0) {
            res.data.rows.forEach((item) => {
              if (item.type === 1) {
                item.typeStr = "班级文化建设研讨登记";
              } else if (item.type === 2) {
                item.typeStr = "班级特色活动登记";
              } else {
                item.typeStr = "班级主题活动登记";
              }
            });
          }

          this.registerTable = res.data.rows;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    loadTeacher() {
      //查询身份是否校内，1是校内
      this.$post("/media/queryAllManager.do", { is_no: 1 })
        .then((res) => {
          this.teacherList = res.data;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // 请求班主任和副班主任，就是所谓的主持人
    loadHostTeacher(classId) {
      this.$post("media/queryClassFManager.do", { classId })
        .then((res) => {
          this.hostTeacherList = res.data;
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    clickAddRegister() {
      this.addTaskVisible = true;
      this.$nextTick(() => {
        this.$refs["taskForm"].resetFields();
      });
      if (this.taskForm.url) {
        this.$refs.upload.clearFiles();
      }
    },
    addConfirm() {
      this.$refs["taskForm"].validate((valid) => {
        if (valid) {
          this.$confirm("是否确认提交该申请？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$post("/base/insertClassCultural.do", {
                name: this.taskForm.name,
                type: this.taskForm.type,
                start_time: this.taskForm.startTime,
                end_time: this.taskForm.endTime,
                url: this.taskForm.url,
              })
                .then((res) => {
                  this.$message({ type: "success", message: res.message });
                  this.loadTask(1);
                  this.addTaskVisible = false;
                })
                .catch((err) => {
                  this.addTaskVisible = false;
                  this.$message({ type: "warning", message: err.message });
                });
            })
            .catch(() => {
              this.$message({ type: "warning", message: "已取消确认" });
            });
        } else {
          return false;
        }
      });
    },
    deletePushData(index, id, type) {
      this.$confirm("是否确认删除该数据？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/deleteClassCultural.do", { id, type })
            .then((res) => {
              this.registerTable.splice(index, 1);
              this.$message({ type: "success", message: res.message });
            })
            .catch((err) => {
              this.$message({ type: "warning", message: err.message });
            });
        })
        .catch(() => {
          this.$message({ type: "warning", message: "已取消删除" });
        });
    },
    viewPushData(row) {
      this.$router.push({
        name: "viewPushData",
        query: { id: row.id, name: row.typeStr, type: row.type },
      });
    },
    registerData(row, type = 0) {
      //type=1考核，assessment为true
      if (type === 1) {
        this.assessment = true;
      } else {
        this.assessment = false;
      }
      this.loadHostTeacher(row.classid);
      this.registerVisible = true;
      this.$nextTick(() => {
        if (this.tableType === 1) {
          this.$refs["registerForm"].resetFields();
          this.registerForm.title = row.title;
          this.registerForm.host = row.host_id;
          this.registerForm.time = row.activity_time;
          if (row.managerids) {
            this.registerForm.participatingTeachers = row.managerids
              .split(",")
              .map(Number);
            this.registerForm.assessmentTeamParticipants = row.managerids1
              .split(",")
              .map(Number);
          }
          this.registerForm.discussionProcessRecord = row.content;
          this.registerForm.id = row.id;
          this.registerForm.culturalId = row.cultural_id;
        }
        if (this.tableType === 2) {
          this.$refs["characteristicActivitiesForm"].resetFields();
          this.characteristicActivitiesForm.name = row.name;
          this.characteristicActivitiesForm.host = row.host_id;
          this.characteristicActivitiesForm.time = row.activity_time;
          if (row.managerids) {
            this.characteristicActivitiesForm.participatingTeachers =
              row.managerids.split(",").map(Number);
            this.characteristicActivitiesForm.assessmentTeamParticipants =
              row.managerids1.split(",").map(Number);
          } else {
            this.characteristicActivitiesForm.participatingTeachers = [];
            this.characteristicActivitiesForm.assessmentTeamParticipants = [];
          }

          this.characteristicActivitiesForm.target = row.objective;
          this.characteristicActivitiesForm.process = row.content;
          this.characteristicActivitiesForm.prepare = row.ready;
          this.characteristicActivitiesForm.activityFeatures = row.is_type; //活动是否有特色
          this.characteristicActivitiesForm.activityEffect = row.level; //活动效果
          this.characteristicActivitiesForm.id = row.id;
          this.characteristicActivitiesForm.culturalId = row.cultural_id;
        }
        if (this.tableType === 3) {
          this.$refs["themeForm"].resetFields();
          this.themeForm.name = row.theme;
          this.themeForm.host = row.host_id;
          this.themeForm.unit = row.unit;
          this.themeForm.activityTime = row.activity_time;
          if (row.managerids) {
            this.themeForm.participatingTeachers = row.managerids
              .split(",")
              .map(Number);
            this.themeForm.assessmentTeamParticipants = row.managerids1
              .split(",")
              .map(Number);
          } else {
            this.themeForm.participatingTeachers = [];
            this.themeForm.assessmentTeamParticipants = [];
          }
          this.themeForm.activityPlace = row.address;
          this.themeForm.target = row.target;
          this.themeForm.technologicalProcessList = [];
          for (let i = 0; i < row.list.length; i++) {
            this.addTechnologicalProcessForm();
          }
          row.list.forEach((technologicalProcess, index) => {
            this.themeForm.technologicalProcessList[index].time =
              technologicalProcess.flow_time;
            this.themeForm.technologicalProcessList[index].project =
              technologicalProcess.name;
            this.themeForm.technologicalProcessList[index].content =
              technologicalProcess.content;
            this.themeForm.technologicalProcessList[index].leading =
              technologicalProcess.duty_id;
            this.themeForm.technologicalProcessList[index].place =
              technologicalProcess.address;
          });
          this.themeForm.culturalId = row.cultural_id;
          this.themeForm.id = row.id;
        }
      });
    },
    registerConfirm() {
      let reSetForm = "registerForm";
      let data = {
        id: this.registerForm.id,
        activity_time: this.registerForm.time,
        title: this.registerForm.title,
        content: this.registerForm.discussionProcessRecord,
        host_id: this.registerForm.host,
        managerids: this.registerForm.assessmentTeamParticipants.toString(),
        managerids1: this.registerForm.participatingTeachers.toString(),
        class_url: this.registerForm.url,
      };
      let apiUrl = "/base/updateClassDiscuss.do";
      if (this.tableType === 2) {
        reSetForm = "characteristicActivitiesForm";
        apiUrl = "/base/updateClassSpecial.do";
        data = {
          id: this.characteristicActivitiesForm.id,
          host_id: this.characteristicActivitiesForm.host,
          managerids:
            this.characteristicActivitiesForm.assessmentTeamParticipants.toString(),
          managerids1:
            this.characteristicActivitiesForm.participatingTeachers.toString(),
          name: this.characteristicActivitiesForm.name,
          objective: this.characteristicActivitiesForm.target,
          activity_time: this.characteristicActivitiesForm.time,
          ready: this.characteristicActivitiesForm.prepare,
          content: this.characteristicActivitiesForm.process,
          class_url: this.characteristicActivitiesForm.url,
        };
      }
      if (this.tableType === 3) {
        reSetForm = "themeForm";
        apiUrl = "/base/updateClassTheme.do";
        let list = [];
        this.themeForm.technologicalProcessList.forEach((row) => {
          let newObject = {};
          newObject.theme_id = this.themeForm.id;
          newObject.flow_time = row.time;
          newObject.name = row.project;
          newObject.content = row.content;
          newObject.address = row.place;
          newObject.duty_id = row.leading;
          list.push(newObject);
        });
        data = {
          id: this.themeForm.id,
          host_id: this.themeForm.host,
          activity_time: this.themeForm.time,
          unit: this.themeForm.unit,
          target: this.themeForm.target,
          them: this.themeForm.name,
          address: this.themeForm.activityPlace,
          managerids: this.themeForm.assessmentTeamParticipants.toString(),
          managerids1: this.themeForm.participatingTeachers.toString(),
          class_url: this.themeForm.url,
          list,
        };
      }
      this.$refs[reSetForm].validate((valid) => {
        if (valid) {
          this.$confirm("是否确认提交该登记？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              if (this.tableType !== 3) {
                this.$post(apiUrl, data)
                  .then((res) => {
                    this.$message({ type: "success", message: res.message });
                    this.loadTask(1);
                    this.registerVisible = false;
                  })
                  .catch((err) => {
                    this.registerVisible = false;
                    this.$message({ type: "warning", message: err.message });
                  });
              } else {
                this.$postJson(apiUrl, data)
                  .then((res) => {
                    this.$message({ type: "success", message: res.message });
                    this.loadTask(1);
                    this.registerVisible = false;
                  })
                  .catch((err) => {
                    this.registerVisible = false;
                    this.$message({ type: "warning", message: err.message });
                  });
              }
            })
            .catch(() => {
              this.$message({ type: "warning", message: "已取消确认" });
            });
        } else {
          return false;
        }
      });
    },
    withdraw(id) {
      this.$confirm("是否确认撤回该数据？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/base/cancelStatusByType.do", {
            id,
            type: this.tableType,
          })
            .then((res) => {
              this.$message({ type: "success", message: res.message });
              this.loadTask(1);
            })
            .catch((err) => {
              this.$message({ type: "warning", message: err.message });
            });
        })
        .catch(() => {
          this.$message({ type: "warning", message: "已取消撤回" });
        });
    },
    assessmentClick() {
      let apiUrl = "/base/updateClassDiscussByCheck.do";
      let data = {
        id: this.registerForm.id,
        culturalId: this.registerForm.culturalId,
      };
      if (this.tableType === 2) {
        console.log(this.characteristicActivitiesForm.activityEffect);
        if (
          !this.characteristicActivitiesForm.activityEffect ||
          !this.characteristicActivitiesForm.activityFeatures
        ) {
          this.$message({
            type: "warning",
            message: "请填写完毕",
          });
          return;
        }
        apiUrl = "/base/updateClassSpecialByCheck.do";
        data = {
          level: this.characteristicActivitiesForm.activityEffect,
          is_type: this.characteristicActivitiesForm.activityFeatures,
          id: this.characteristicActivitiesForm.id,
          culturalId: this.characteristicActivitiesForm.culturalId,
        };
      }
      if (this.tableType === 3) {
        apiUrl = "/base/updateClassThemeByCheck.do";
        data = {
          id: this.themeForm.id,
          culturalId: this.themeForm.culturalId,
        };
      }
      this.$confirm("是否确认考核？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post(apiUrl, data)
            .then((res) => {
              this.registerVisible = false;
              this.$message({ type: "success", message: res.message });
              this.loadTask(1);
            })
            .catch((err) => {
              this.$message({ type: "warning", message: err.message });
            });
        })
        .catch(() => {
          this.$message({ type: "warning", message: "已取消确认" });
        });
    },
    addTechnologicalProcessForm() {
      let newObject = {
        time: "",
        project: "",
        content: "",
        leading: "",
        place: "",
      };
      this.themeForm.technologicalProcessList.push(newObject);
    },
    deleteTechnologicalProcessForm(index) {
      this.themeForm.technologicalProcessList.splice(index, 1);
    },
    // 导出
    downOtherAward() {
      const that = this;
      let paramas = new FormData();
      paramas.append("type", this.queryType); //类型1研讨2特色活动3主题活动
      paramas.append("status", this.queryState); //1未完成2已完成
      if (this.queryTime) {
        paramas.append("begin_time", this.queryTime[0]);
        paramas.append("end_time", this.queryTime[1]);
      }
      this.$postFile("/base/exportClassWordZip.do", paramas)
        .then((res) => {
          console.log(res);
          const blob = new Blob([res.data], {
            type: "application/zip;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "导出";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    querySemesterChange(value) {
      this.queryYear = value;
    },
    enclosureUploadSuccessTaskForm(res, file) {
      if (this.taskForm.url) {
        this.taskForm.url = `${this.taskForm.url},${file.response.message}`;
      } else {
        this.taskForm.url = file.response.message;
      }
    },
    enclosureUploadSuccessRegisterForm(res, file) {
      if (this.registerForm.url) {
        this.registerForm.url = `${this.registerForm.url},${file.response.message}`;
      } else {
        this.registerForm.url = file.response.message;
      }
    },
    enclosureUploadSuccessCharacteristicActivitiesForm(res, file) {
      if (this.characteristicActivitiesForm.url) {
        this.characteristicActivitiesForm.url = `${this.characteristicActivitiesForm.url},${file.response.message}`;
      } else {
        this.characteristicActivitiesForm.url = file.response.message;
      }
    },
    enclosureUploadSuccessThemeForm(res, file) {
      if (this.themeForm.url) {
        this.themeForm.url = `${this.themeForm.url},${file.response.message}`;
      } else {
        this.themeForm.url = file.response.message;
      }
    },
    loadFile(url) {
      let urlList = url.split(",");
      if (urlList.length === 1) {
        window.open(this.$fileUploadDomain + url);
      } else {
        this.downZip(urlList);
      }
    },
    // 批量下载
    downZip(urlList) {
      const that = this;
      this.$postFile("/base/ZipOss.do", urlList)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/zip",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "导出";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    // 文件移除的钩子
    onRemoveFileTaskForm(res, file) {
      this.taskForm.url = "";
      if (file.length !== 0) {
        file.forEach((newFile) => {
          if (this.taskForm.url) {
            this.taskForm.url = `${this.taskForm.url},${newFile.response.message}`;
          } else {
            this.taskForm.url = newFile.response.message;
          }
        });
      }
    },
    onRemoveFileRegisterForm(res, file) {
      this.registerForm.url = "";
      file.forEach((newFile) => {
        if (this.registerForm.url) {
          this.registerForm.url = `${this.registerForm.url},${newFile.response.message}`;
        } else {
          this.registerForm.url = newFile.response.message;
        }
      });
    },
    onRemoveFileCharacteristicActivitiesForm(res, file) {
      this.characteristicActivitiesForm.url = "";
      file.forEach((newFile) => {
        if (this.characteristicActivitiesForm.url) {
          this.characteristicActivitiesForm.url = `${this.characteristicActivitiesForm.url},${newFile.response.message}`;
        } else {
          this.characteristicActivitiesForm.url = newFile.response.message;
        }
      });
    },
    onRemoveFileThemeForm(res, file) {
      this.themeForm.url = "";
      file.forEach((newFile) => {
        if (this.themeForm.url) {
          this.themeForm.url = `${this.themeForm.url},${newFile.response.message}`;
        } else {
          this.themeForm.url = newFile.response.message;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.header-button-query {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}
.header-frame {
  margin: 0 5px;
}
.header-button {
  width: 180px;
}
.form-height {
  height: 500px;
  overflow-x: auto;
}
.div-themeForm-technologicalProcess {
  text-align: center;
}
</style>
